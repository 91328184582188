import { Guid } from '@komo-tech/core/models/Guid';
import { MinMax } from '@komo-tech/core/models/MinMax';
import { OptionModel } from '@komo-tech/core/models/OptionModel';
import {
  utcFormatDateTime,
  utcFormatDateTimeRange
} from '@komo-tech/core/utils/date';
import { isNumber } from '@komo-tech/core/utils/type';
import {
  isUrlSlugReserved,
  isValidUrl,
  isValidUrlSlug
} from '@komo-tech/core/utils/url';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import isNil from 'lodash/isNil';

export const guidValidator =
  (overrideMessage?: string) =>
  (value: string): string | undefined => {
    if (!value) return undefined;
    return Guid.isValid(value)
      ? undefined
      : overrideMessage || 'Not a valid GUID';
  };

//TODO SPLIT ALL THESE IN THEIR OWN FILE
export function numberNotGreaterThanFieldValidator<TValues = any>(
  fieldName: keyof TValues,
  overrideMessage?: string
) {
  return (value: any, values: TValues): string | undefined => {
    if (isNil(value)) return null;
    if (!values) return null;
    if (value > values[fieldName]) {
      return (
        overrideMessage || `Field must not be greater than ${fieldName as any}`
      );
    }
    return undefined;
  };
}

export function numberNotLessThanFieldValidator<TValues = any>(
  fieldName: keyof TValues,
  overrideMessage?: string
) {
  return (value: any, values: TValues): string | undefined => {
    if (isNil(value)) return null;
    if (!values) return null;
    if (value < values[fieldName]) {
      return (
        overrideMessage || `Field must not be less than ${fieldName as any}`
      );
    }
    return undefined;
  };
}

export const minCharLengthValidator = (
  min: number,
  overrideMessage?: string
) => {
  return (value: string | number): string | undefined => {
    if (!value || !min || min < 0) return null;
    if (value.toString().length < min) {
      return overrideMessage || `Must be at least ${min} characters`;
    }
    return undefined;
  };
};

export const maxCharLengthValidator = (
  max: number,
  overrideMessage?: string
) => {
  return (value: string | number): string | undefined => {
    if (!value || !max || max < 0) return null;
    if (value.toString().length > max) {
      return overrideMessage || `Must be no more than ${max} characters`;
    }
    return undefined;
  };
};

export const mustIncludeCode = (code: string, overrideMessage?: string) => {
  return (value: string): string | undefined => {
    if (!value || !code) return null;
    if (!value.includes(code)) {
      return overrideMessage || `Distribution must include ${code}`;
    }
    return undefined;
  };
};

export function lessThanOrEqualNumberValidator(
  max: number,
  overrideMessage?: string
) {
  return (value: number): string | undefined => {
    if (isNil(value)) return null;
    if (value > max) {
      return (
        overrideMessage ||
        `Must be less than or equal to ${max.toLocaleString()}`
      );
    }
    return undefined;
  };
}

export function lessThanNumberValidator<TValues>(
  otherField: keyof TValues,
  overrideMessage?: string
) {
  return (value: any, values: TValues): string | undefined => {
    if (!value) return undefined;
    const otherNumber = values[otherField] as any;
    if (!otherNumber) return null;
    if (value >= otherNumber) {
      return (
        overrideMessage ||
        `Number must be less than ${otherNumber.toLocaleString()}`
      );
    }
    return undefined;
  };
}

export function greaterThanOrEqualNumberValidator(
  min: number,
  overrideMessage?: string
) {
  return (value: number): string | undefined => {
    if (isNil(value)) return null;
    if (value < min) {
      return overrideMessage || `Must be greater than or equal to ${min}`;
    }
    return undefined;
  };
}

export function notEqualToNumberValidator(
  numberValue: number,
  overrideMessage?: string
) {
  return (value: number): string | undefined => {
    if (isNil(value) || isNil(numberValue)) return null;
    if (value === numberValue) {
      return overrideMessage || `Cannot be ${numberValue.toLocaleString()}`;
    }
    return undefined;
  };
}

export function greaterThanNumberValidator(
  min: number,
  overrideMessage?: string
) {
  return (value: number): string | undefined => {
    if (isNil(value)) return null;
    if (!(value > min)) {
      return overrideMessage || `Must be greater than ${min}`;
    }
    return undefined;
  };
}

export function greaterThanNumberIfValidator<TValues>(
  min: number,
  predicate: (value: number, values: TValues) => boolean,
  overrideMessage?: string
) {
  return (value: number, values: TValues): string | undefined => {
    if (!predicate(value, values)) {
      return undefined;
    }
    if (isNil(value)) return null;
    if (!(value > min)) {
      return overrideMessage || `Must be greater than ${min}`;
    }
    return undefined;
  };
}

export const mustBeTrueRequiredValidator =
  (overrideMessage?: string) =>
  (value: any): string | undefined => {
    if (!value) {
      return overrideMessage || 'Field is required';
    }
    return undefined;
  };

export const equalStringValidator =
  (
    text: string,
    options?: { ignoreCase?: boolean; trim?: boolean; overrideMessage?: string }
  ) =>
  (value: string): string | undefined => {
    if (text === null || text === undefined) {
      return undefined;
    }

    const message = options?.overrideMessage || `Must match '${text}'`;
    if (!value) {
      return message;
    }

    const ignoreCase = !!options?.ignoreCase;
    const trim = !!options?.trim;
    const valueToCheck = ignoreCase ? value.toLowerCase() : value;
    const textToCheck = ignoreCase ? text.toLowerCase() : text;
    if (trim && valueToCheck.trim() !== textToCheck.trim()) {
      return message;
    } else if (!trim && valueToCheck !== textToCheck) {
      return message;
    }
    return undefined;
  };

export function notEqualStringListValidator(
  stringValueList: string[],
  overrideMessage?: string
) {
  return (value: string): string | undefined => {
    if (isNil(value) || isNil(stringValueList)) return null;
    if (
      !!stringValueList.find(
        (stringValue) =>
          value.toLocaleLowerCase() === stringValue.toLocaleLowerCase()
      )
    ) {
      return overrideMessage || `Cannot be ${value}`;
    }
    return undefined;
  };
}

export const previousDateValidator = (overrideMessage?: string) => {
  return (value?: Date): string | null => {
    if (!value) return null;
    if (value > new Date()) {
      return (
        overrideMessage ||
        'You must select a previous or current date and time.'
      );
    }
    return null;
  };
};

export const futureDateValidator = (overrideMessage?: string) => {
  return (value?: Date): string | null => {
    if (!value) return null;
    if (value < new Date()) {
      return overrideMessage || 'You must select a date and time in the future';
    }
    return null;
  };
};

export const futureZonedDateValidator = (
  timeZone: string,
  overrideMessage?: string
) => {
  return (value?: Date): string | null => {
    if (!value) return null;
    if (!timeZone) return null;
    if (zonedTimeToUtc(value, timeZone) < new Date()) {
      return overrideMessage || 'You must select a date and time in the future';
    }
    return null;
  };
};

export function afterDateValidator<TValues>(
  field: keyof TValues,
  overrideMessage?: string
) {
  return (value: any, values: TValues): string | undefined => {
    if (!value) return undefined;
    const otherDate = values[field] as any;
    if (!otherDate) return null;
    if (value <= otherDate) {
      return (
        overrideMessage ||
        `You must select a date and time after ${utcFormatDateTime(otherDate)}`
      );
    }
    return undefined;
  };
}

export const notInRangeDateValidator = (
  ranges: MinMax<Date>[],
  overrideMessage?: (range: MinMax<Date>) => string
) => {
  return (value: Date): string | undefined => {
    if (!value || ranges?.length) return undefined;
    const inValidRange = ranges.find((x) => value >= x.min && value <= x.max);
    if (inValidRange) {
      return overrideMessage
        ? overrideMessage(inValidRange)
        : `Cannot be in range ${utcFormatDateTimeRange(
            inValidRange.min,
            inValidRange.max
          )}`;
    }
    return undefined;
  };
};

export const inRangeDateValidator = (
  range: MinMax<Date>,
  overrideMessage?: string
) => {
  return (value: Date): string | undefined => {
    if (!value) return undefined;
    const validRange = value >= range.min && value <= range.max;
    if (!validRange) {
      return (
        overrideMessage ||
        `Must be in range ${utcFormatDateTimeRange(range.min, range.max)}`
      );
    }
    return undefined;
  };
};

export const in15MinuteBlockDateValidator = (overrideMessage?: string) => {
  return (value: Date): string | undefined => {
    if (!value) return undefined;
    if (value.getMinutes() % 15 !== 0) {
      return overrideMessage || `Must be in 15 minute blocks`;
    }
    return undefined;
  };
};

export function lessThanOrEqualNumberFieldValidator<TValues>(
  field: keyof TValues,
  overrideMessage?: string
) {
  return (value: number, values: TValues): string | undefined => {
    if (isNil(value) || !isNumber(value)) return undefined;
    const fieldValue = values[field] as any as number;
    if (isNil(fieldValue) || !isNumber(fieldValue)) return undefined;
    if (value > fieldValue) {
      return overrideMessage || `Must be less than or equal to ${field as any}`;
    }
    return undefined;
  };
}

export function greaterThanOrEqualNumberFieldValidator<TValues>(
  field: keyof TValues,
  overrideMessage?: string
) {
  return (value: number, values: TValues): string | undefined => {
    if (isNil(value) || !isNumber(value)) return undefined;
    const fieldValue = values[field] as any as number;
    if (isNil(fieldValue) || !isNumber(fieldValue)) return undefined;
    if (value < fieldValue) {
      return (
        overrideMessage || `Must be greater than or equal to ${field as any}`
      );
    }
    return undefined;
  };
}

export function predicateValidator<TValues, TValidateData>(
  predicate: (options: {
    value: any;
    values: TValues;
    data?: TValidateData;
  }) => boolean,
  message: (options: {
    value: any;
    values: TValues;
    data?: TValidateData;
  }) => string
) {
  return (
    value: any,
    values: TValues,
    data?: TValidateData
  ): string | undefined => {
    if (!value) return undefined;
    if (!predicate({ value, values, data })) {
      return message({ value, values, data });
    }

    return undefined;
  };
}

/**
 * Validation to ensure the value matches the given regex.
 * @param regex the regex to test the value against.
 * @param overrideMessage the override message
 */
export const regexValidator = (regex: RegExp, overrideMessage?: string) => {
  return (value: string): string | undefined => {
    if (!value) return null;
    if (!regex.test(value.toString())) {
      return overrideMessage || `Did not meet required criteria`;
    }
    return undefined;
  };
};

/**
 * Validation to ensure the value does not match the given regex.
 * @param regex the regex to test the value against.
 * @param overrideMessage the override message
 */
export const antiRegexValidator = (regex: RegExp, overrideMessage?: string) => {
  return (value: string): string | undefined => {
    if (!value) return null;
    if (regex.test(value.toString())) {
      return overrideMessage || `Did not meet required criteria`;
    }
    return undefined;
  };
};

/**
 * Validation to ensure the value matches the given regex.
 * @param regex the regex to test the value against.
 * @param overrideMessage the override message
 */
export const multipleRegexValidator = (
  regex: RegExp[],
  overrideMessage?: string
) => {
  return (value: string): string | undefined => {
    if (!value) return null;
    if (!regex.some((x) => x.test(value.toString()))) {
      return overrideMessage || `Did not meet required criteria`;
    }
    return undefined;
  };
};

export const urlParameterValidator = (overrideMessage?: string) => {
  return (value: string): string | undefined => {
    if (!value) return null;
    if (value.includes('?')) {
      return overrideMessage || `Cannot contain "?"`;
    }
    if (value.includes('&')) {
      return overrideMessage || `Cannot contain "&"`;
    }
    const parsed = encodeURIComponent(value);
    if (parsed !== value) {
      return overrideMessage || `Not a valid url. Recommended ${parsed}`;
    }
    return undefined;
  };
};

export function integerValidator(overrideMessage?: string) {
  return (value: number): string | undefined => {
    if (value === undefined || value === null) {
      return undefined;
    }
    if (!Number.isInteger(value)) {
      return overrideMessage || `Must be integer`;
    }
    return undefined;
  };
}

export const doesNotContainWordsValidator =
  (words: string[], overrideMessage?: (badWordsFound: string[]) => string) =>
  (value: string): string | undefined => {
    if (value === null || value === undefined || !words?.length)
      return undefined;
    const valueLower = value.toLowerCase();
    const badWords = words.filter((badWord) => {
      const badWordLower = badWord.toLowerCase();
      return valueLower.includes(badWordLower);
    });

    if (badWords.length) {
      return overrideMessage(badWords) || 'Certain words are not allowed';
    }

    return undefined;
  };

export function arrayPredicateMaxCountValidator(
  predicate: (item) => boolean,
  maxCount: number,
  message: string
) {
  return (value: any[]): string | undefined => {
    if (isNil(value) || !value.length) {
      return;
    }

    const exceedsMax = value.filter(predicate).length > maxCount;
    if (exceedsMax) {
      return message;
    }
  };
}

export function fieldNameValidator(overrideMessage?: string) {
  return (value: string): string | undefined => {
    if (!(value || '').trim()) {
      return overrideMessage || 'Field is required';
    }
    return /[a-zA-Z][a-zA-Z0-9_]*/i.test(value)
      ? null
      : overrideMessage ||
          'Invalid field ID (only letters, numbers and underscores allowed)';
  };
}

export const urlValidator = (
  requireHttps?: boolean,
  overrideMessage?: string
) => {
  return (value: string): string | undefined => {
    if (!value) return null;
    const [isValid, { noProtocol, isHttps, isValidHostName }] = isValidUrl(
      value,
      requireHttps
    );

    if (isValid) {
      return null;
    }

    if (overrideMessage) return overrideMessage;

    if (noProtocol) {
      return "Missing 'https://";
    }

    if (isValidHostName) {
      return 'Not a valid URL hostname';
    }

    if (requireHttps && !isHttps) {
      return 'Must be https';
    }

    return 'Not a valid url';
  };
};

export const urlSlugValidator = (required: boolean = true) => {
  return (value: string): string | undefined => {
    if (!required && value.trim() === '') {
      return null;
    }

    if (isValidUrlSlug(value)) {
      return null;
    }

    return 'Not a valid slug';
  };
};

export const urlSlugReservedKeywordsValidator = (
  keywords: string[],
  checkHttpStatusCodes: boolean = true
) => {
  return (value: string): string | undefined => {
    if (value.trim() === '') {
      return null;
    }

    if (isUrlSlugReserved(value, keywords, checkHttpStatusCodes)) {
      return 'This slug is reserved';
    }

    return null;
  };
};

export const validDomainValidator = (overrideMessage?: string) => {
  return (value: string): string | undefined => {
    if (!value) return null;
    // Regex to check valid Domain Name
    const regex = new RegExp(
      /^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
    );
    if (regex.test(value) == true) {
      return null;
    } else {
      return overrideMessage || 'Not a valid domain';
    }
  };
};

export interface IHasVariables {
  variables: OptionModel<string>[];
}

export const liquidVariableValidator = (
  value: string,
  _: any,
  data: IHasVariables
): string | undefined => {
  if (!value) return undefined;

  //Sanitises html editor quotes
  const sanitised = value.replaceAll('&quot;', '"');

  const usedTemplates = sanitised.match(/{{(.*?)}}/g)?.map(function (v) {
    return v.replace(/{{|}}| /g, '');
  });
  const variables = data.variables.map((v) => {
    return v.value;
  });
  //|if |else |endif |
  const conditionalList = [];
  if (sanitised.includes('{%')) {
    sanitised.match(/{%(.*?)%}/g)?.map(function (c) {
      //remove {%, %} and comments (" becomes &quot; from the html editor)
      const varWithoutComments = c.replace(
        /{% ?| ?%}|".*?"|&quot;.*?&quot;|\bif\b|\belse\b|\bendif\b|\bblank\b|==|!=|\bunless\b|\bendcase\b|\bendunless\b|\belsif\b|\bcase\b|\bwhen\b/g,
        ''
      );
      varWithoutComments.split(' ').forEach((v) => {
        if (v) conditionalList.push(v);
      });
    });
  }

  if (
    usedTemplates?.some((v) => !variables.includes(v)) ||
    conditionalList?.some((v) => !variables.includes(v))
  )
    return `Invalid code used`;

  return undefined;
};
