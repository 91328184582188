import { Guid } from '@komo-tech/core/models/Guid';

/**
 * Enum representing how much of the card is visible. The number denotes the minimum percentage visible,
 * up to the next visibility band.
 * E.g. a card with "Visible25" will have between 25-50% visibility.
 * These values are used in sorting so be sure to maintain the correct values.
 */
export enum CardVisibilityLevels {
  Visible5 = 5,
  Visible25 = 25,
  Visible50 = 50,
  Visible75 = 75,
  Visible95 = 95
}

export interface VisibleCard {
  cardId: Guid;
  timeVisible: number;
  visibilityLevel: CardVisibilityLevels;
}

export const orderByMostVisibleThenByMostRecentDescending = (
  a: VisibleCard,
  b: VisibleCard
) => b.visibilityLevel - a.visibilityLevel || b.timeVisible - a.timeVisible;
