import { FieldValidator } from './Validator';

const defaultMessage =
  'Must contain at least 1 lowercase letter, 1 uppercase and 1 number';

export const passwordValidator = (message?: string): FieldValidator => {
  return (value: string) => {
    if (!value) return undefined;
    const isValid =
      /[a-z]/.test(value) && /[A-Z]/.test(value) && /[0-9]/.test(value);
    return !isValid ? message || defaultMessage : null;
  };
};
