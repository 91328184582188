// Src: Material UI utils useForkRef https://github.com/mui/material-ui/blob/dec32b361714bed9c73ee1360c4b6390769bf9f5/packages/mui-utils/src/useForkRef.ts#L4

import { MutableRefObject, Ref, useMemo } from 'react';

/**
 * TODO v5: consider making it private
 *
 * passes {value} to {ref}
 *
 * WARNING: Be sure to only call this inside a callback that is passed as a ref.
 * Otherwise, make sure to cleanup the previous {ref} if it changes. See
 * https://github.com/mui/material-ui/issues/13539
 *
 * Useful if you want to expose the ref of an inner component to the public API
 * while still using it inside the component.
 * @param ref A ref callback or ref object. If anything falsy, this is a no-op.
 */
function setRef<T>(
  ref:
    | MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null
): void {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref?.current) {
    ref.current = value;
  }
}

export function useForkRef<InstanceA, InstanceB>(
  refA: Ref<InstanceA> | null | undefined,
  refB: Ref<InstanceB> | null | undefined
): Ref<InstanceA & InstanceB> | null {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}
