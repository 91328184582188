import cx from 'clsx';
import React, { cloneElement, FC, ReactElement } from 'react';

import { OptionalTooltipProps, Tooltip } from '../Tooltip';
import classes from './InfoDecorator.module.css';

export interface InfoDecoratorProps
  extends Omit<OptionalTooltipProps, 'children'> {
  children?: ReactElement;
  withHoverBg?: boolean;
}
/**
 * @see https://mantine.dev/core/tooltip/
 */
export const InfoDecorator: FC<InfoDecoratorProps> = ({
  children,
  label,
  withHoverBg = true,
  events = {
    hover: true,
    focus: true,
    touch: true
  },
  ...rest
}) => {
  if (!children) {
    return <></>;
  }

  if (!label) {
    return <>{children}</>;
  }

  const reactChild = children as ReactElement;

  const childProps = {
    className: cx(reactChild.props?.className, 'info-decorator-underline', {
      [classes.withBg]: withHoverBg
    })
  };

  return (
    <Tooltip label={label} events={events} {...rest}>
      {cloneElement(reactChild, childProps)}
    </Tooltip>
  );
};
