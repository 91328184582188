import { Box } from '@komo-tech/ui/Box';
import { Title } from '@komo-tech/ui/Title';
import React, { FC, ReactNode } from 'react';

import classes from './AuthPanel.module.css';

interface Props {
  title?: string;
  children: ReactNode;
  size?: 'Small' | 'Medium';
}

export const AuthPanel: FC<Props> = ({ title, children, size = 'Small' }) => {
  return (
    <Box className={classes.root} data-size={size}>
      <Box ta={'center'}>
        {title && (
          <Title order={4} my={'1rem'}>
            {title}
          </Title>
        )}
      </Box>
      {children}
    </Box>
  );
};
