import { useState } from 'react';

interface UseUncontrolledInput<TValue = unknown, TChangeValue = TValue> {
  /** Value for controlled state */
  value?: TValue;

  /** Initial value for uncontrolled state */
  defaultValue?: TValue;

  /** Final value for uncontrolled state when value and defaultValue are not provided */
  finalValue?: TValue;

  resolveValue?: (value: TChangeValue) => TValue;

  sanitiseValue?: (value: TValue) => TValue;

  /** Controlled state onChange handler */
  onChange?: (changeValue: TChangeValue, value: TValue) => void;
}

export function useUncontrolled<TValue = unknown, TChangeValue = TValue>({
  value,
  defaultValue,
  finalValue,
  onChange = () => {},
  resolveValue = (v) => v as unknown as TValue,
  sanitiseValue = (x) => x
}: UseUncontrolledInput<TValue, TChangeValue>): [
  TValue,
  (v: TChangeValue) => void,
  boolean
] {
  const [uncontrolledValue, setUncontrolledValue] = useState(
    defaultValue !== undefined
      ? sanitiseValue(defaultValue)
      : sanitiseValue(finalValue)
  );

  const handleUncontrolledChange = (changeValue: TChangeValue) => {
    const value = sanitiseValue(resolveValue(changeValue));
    setUncontrolledValue(value);
    onChange?.(changeValue, value);
  };

  const handleControlledChange = (changeValue: TChangeValue) => {
    const value = sanitiseValue(resolveValue(changeValue));
    onChange?.(changeValue, value);
  };

  if (value !== undefined) {
    return [value, handleControlledChange, true];
  }

  return [uncontrolledValue, handleUncontrolledChange, false];
}
