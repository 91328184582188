import {
  createPolymorphicComponent as createMantinePolymorphicComponent,
  darken as mantineDarken,
  factory as mantineFactory,
  getBreakpointValue as getMantineBreakpointValue,
  getFontSize as mantineGetFontSize,
  getSpacing as getMantineSpacing,
  getThemeColor as getMantineThemeColor,
  isLightColor as mantineIsLightColor,
  lighten as mantinelighten,
  parseThemeColor as mantineParseThemeColor,
  rem as mantineRem,
  rgba as mantineRgba,
  useProps as useMantineProps,
  useStyles as useMantineStyles
} from '@mantine/core';
import _randomColor from 'randomcolor';

import { ExtendedColorPaletteKeys } from './ExtendedColors';
import { BaseColor } from './types';

export const remBase = 16;
export const pxToRem = (pixels: number) => {
  return pixels / remBase;
};

export const factory = mantineFactory;
export const getThemeColor = getMantineThemeColor;
export const getFontSize = mantineGetFontSize;
export const getSpacing = getMantineSpacing;
export const isLightColor = mantineIsLightColor;
export const darken = mantineDarken;
export const lighten = mantinelighten;
export const useThemeComponentProps = useMantineProps;
export const useThemeComponentStyles = useMantineStyles;
export const getBreakpointValue = getMantineBreakpointValue;
export const rem = mantineRem;
export const rgba = mantineRgba;
export const parseThemeColor = mantineParseThemeColor;
export const createPolymorphicComponent = createMantinePolymorphicComponent;
type RandomColorFn = (options?: any) => string;
export const randomColor = _randomColor as RandomColorFn;

const excludedNiceColors: BaseColor[] = [
  'gray',
  'dark',
  'pink',
  'green',
  'komo-primary',
  'indigo',
  'sky-blue',
  'bright-blue',
  'deep-blue',
  'komo-blue',
  'pale-purple',
  'deep-red'
];

const NiceColorKeys = ExtendedColorPaletteKeys.filter(
  (x) => excludedNiceColors.indexOf(x) === -1
);

const niceColorsLength = NiceColorKeys.length;

export const getNiceColor = (index: number) => {
  const normalisedColorIndex = index % niceColorsLength;
  const baseColor = NiceColorKeys[normalisedColorIndex];

  return {
    baseColor,
    filledColor: `var(--mantine-color-${baseColor}-filled)`
  };
};
