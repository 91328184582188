import { KeyOf } from '@komo-tech/core/models/path';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { FieldValues } from 'react-hook-form';

import { FieldValidator } from './Validator';

type Equals = (value: unknown, matchField: unknown) => boolean;

interface Options<TFieldValues extends FieldValues = FieldValues> {
  matchField: KeyOf<TFieldValues>;
  equals?: Equals;
  message?: string;
}

export function matchFieldValidator<
  TFieldValues extends FieldValues = FieldValues
>(options: Options<TFieldValues>): FieldValidator<TFieldValues> {
  return (value, values) => {
    if (isNil(value)) return null;
    const equals: Equals = options?.equals || ((a, b) => a === b);
    const isValid = equals(value, get(values, options.matchField));
    return !isValid
      ? options.message || `Does not match ${options.matchField}`
      : null;
  };
}
