import type { SkeletonProps as MantineSkeletonProps } from '@mantine/core';
import { Skeleton as MantineSkeleton } from '@mantine/core';
import { forwardRef } from 'react';
/**
 * @see https://mantine.dev/core/skeleton/
 */
export const Skeleton = MantineSkeleton;
export type SkeletonProps = MantineSkeletonProps;

export const OptionalSkeleton = forwardRef<HTMLDivElement, SkeletonProps>(
  ({ children, visible = false, ...rest }, ref) => {
    return (
      <>
        {!visible ? (
          children
        ) : (
          <Skeleton ref={ref} {...rest}>
            {children}
          </Skeleton>
        )}
      </>
    );
  }
);
