import { isServer } from '@komo-tech/core/utils/browser';
import { ApiBase } from '@komo-tech/ui/api/Base';
import { ApiHeaders } from '@komo-tech/ui/api/constants';

import { KomoApiBaseUrl } from '@/common/utils/Constants';
import { SiteCache } from '@/front/utils/SiteCache';

const getPublicHeaders = (): HeadersInit => {
  const headers = {} as HeadersInit;
  const cacheState = SiteCache.getState();
  const url = cacheState.embeddedHostUrl;
  const userId = cacheState.userId;
  const pageId = cacheState.pageId;
  const pageSlug = cacheState.pageSlug;
  if (userId) {
    headers[ApiHeaders.UserSessionId] = userId;
  }
  if (url) {
    headers[ApiHeaders.EmbeddedHostUrl] = url;
  }
  if (pageId) {
    headers[ApiHeaders.PageId] = pageId;
  }
  if (pageSlug) {
    headers[ApiHeaders.PageSlug] = pageSlug;
  }

  if (isServer()) {
    headers[ApiHeaders.Ssr] = true;
  }
  return headers;
};

/**
 * This class should not be used directly unless for testing.
 *
 * Use "PublicLiveApi"
 */
export class PublicApi extends ApiBase {
  constructor(basePathFn: () => string) {
    super(basePathFn, (config) => {
      config = config || {};
      return {
        ...config,
        headers: {
          ...getPublicHeaders(),
          ...config.headers
        }
      };
    });
  }
}

export const PublicLiveApi = new PublicApi(
  () => `${KomoApiBaseUrl}/api/v1/live`
);
