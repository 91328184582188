import Head from 'next/head';
import { FC } from 'react';

import { MetaValues } from '@/common/models/MetaValues';
import { DefaultFavIconPath } from '@/common/utils/Constants';

export interface MetaTagsProps {
  meta: MetaValues;
}

export const MetaTags: FC<MetaTagsProps> = ({ meta }) => {
  const {
    metaTitle,
    metaDescription,
    metaImage,
    metaIcon,
    metaIndex,
    metaGoogleSiteVerification
  } = meta;

  const canonicalUrl = '';
  const hasUrl = canonicalUrl ? true : false;
  const hasDescription = !!metaDescription;
  const hasImageUrl = !!metaImage?.url;
  const hasGoogleSiteVerification = !!metaGoogleSiteVerification;

  return (
    <Head>
      {/* Favicon */}
      <link
        rel="icon"
        type="image/png"
        href={metaIcon?.url || DefaultFavIconPath}
      />

      {/* Meta */}
      <meta
        key="robots"
        name="robots"
        content={metaIndex ? 'index, follow' : 'noindex, follow'}
      />
      {hasDescription && (
        <meta key="description" name="description" content={metaDescription} />
      )}
      {/* Google */}
      {hasGoogleSiteVerification && (
        <meta
          key="google-site-verification"
          name="google-site-verification"
          content={metaGoogleSiteVerification}
        />
      )}
      {/* Schema.org */}
      <meta key="itemProp:name" itemProp="name" content={metaTitle} />
      {hasDescription && (
        <meta
          key="itemProp:description"
          itemProp="description"
          content={metaDescription}
        />
      )}
      {hasImageUrl && (
        <meta key="itemProp:image" itemProp="image" content={metaImage.url} />
      )}
      {hasUrl && (
        <meta key="itemProp:url" itemProp="url" content={canonicalUrl} />
      )}
      {/* Twitter */}
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:title" name="twitter:title" content={metaTitle} />
      {hasImageUrl && (
        <meta
          key="twitter:image"
          name="twitter:image"
          content={metaImage.url}
        />
      )}
      {hasDescription && (
        <meta
          key="twitter:description"
          name="twitter:description"
          content={metaDescription}
        />
      )}
      {/* Open Graph */}
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:title" property="og:title" content={metaTitle} />
      {hasUrl && <meta key="og:url" property="og:url" content={canonicalUrl} />}
      {hasImageUrl && (
        <>
          <meta key="og:image" property="og:image" content={metaImage.url} />
          <meta key="og:image:width" property="og:image:width" content="640" />
          <meta
            key="og:image:height"
            property="og:image:height"
            content="442"
          />
        </>
      )}
      {hasDescription && (
        <meta
          key="og:description"
          property="og:description"
          content={metaDescription}
        />
      )}
    </Head>
  );
};
