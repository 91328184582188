import {
  Box as MantineBox,
  BoxProps as MantineBoxProps,
  createVarsResolver,
  ElementProps,
  Factory,
  factory,
  getSpacing,
  MantineSpacing,
  StylesApiProps,
  useProps,
  useStyles
} from '@mantine/core';

import classes from './Form.module.css';

export type FormStylesNames = 'root';

export type FormCssVariables = {
  root: '--form-gap';
};

export interface FormProps
  extends MantineBoxProps,
    StylesApiProps<FormFactory>,
    ElementProps<'form', 'color'> {
  gap?: MantineSpacing;
}

export type FormFactory = Factory<{
  props: FormProps;
  ref: HTMLFormElement;
  stylesNames: FormStylesNames;
  vars: FormCssVariables;
}>;

const varsResolver = createVarsResolver<FormFactory>((_, { gap }) => ({
  root: {
    '--form-gap': getSpacing(gap)
  }
}));

const defaultProps: Partial<FormProps> = {
  gap: 'md'
};

export const Form = factory<FormFactory>((_props, ref) => {
  const props = useProps('Form', defaultProps, _props);

  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    children,
    gap,
    vars,
    variant,
    ...others
  } = props;

  const getStyles = useStyles<FormFactory>({
    name: 'Form',
    props,
    className,
    style,
    classes,
    classNames,
    styles,
    unstyled,
    vars,
    varsResolver
  });

  return (
    <MantineBox
      component="form"
      {...getStyles('root')}
      noValidate
      ref={ref}
      {...others}
    >
      {children}
    </MantineBox>
  );
});
