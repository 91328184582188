import { Guid } from '@komo-tech/core/models/Guid';
import { OperationResult } from '@komo-tech/core/models/OperationResult';

import { SiteUser } from '@/common/models/SiteUser';
import { FrontSessionStore } from '@/front/components/site/user-session-v2/FrontSessionStore';
import { PublicLiveApi } from '@/front/data/PublicApi';
import { SiteCache } from '@/front/utils/SiteCache';

import {
  OAuthAuthorizeUserRequest,
  OAuthUserSignInRequest,
  OAuthUserSignInResponse,
  ThuziSignInRequest,
  ThuziSignInResponse,
  UserForgotPasswordRequest,
  UserPreSignUpRequest,
  UserPreSignUpResponse,
  UserResetPasswordRequest,
  UserSignInCodeRequest,
  UserSignInConfirmEmailRequest,
  UserSignInRequest,
  UserSignInResponse,
  UserSignUpRequest,
  UserSignUpResponse,
  ValidateUserEmailRequest,
  ValidateUserEmailResponse
} from './_types';

export const PublicAuthActions = {
  validateEmailAsync: async (siteId: Guid, request: ValidateUserEmailRequest) =>
    new ValidateUserEmailResponse(
      await PublicLiveApi.postAsync(
        `auth/${siteId.toString()}/validate-email`,
        request
      )
    ),

  oAuthSignInAsync: async (
    request: OAuthUserSignInRequest
  ): Promise<OAuthUserSignInResponse> => {
    try {
      await PublicLiveApi.postAsync<OAuthUserSignInResponse>(
        `auth/oauth/signin`,
        request
      );
      return { success: true };
    } catch (err) {
      console.error(err);
      return { success: false };
    }
  },

  oAuthAuthorizeUserAsync: async (
    request: OAuthAuthorizeUserRequest
  ): Promise<OAuthUserSignInResponse> => {
    try {
      await PublicLiveApi.postAsync<OAuthUserSignInResponse>(
        `auth/oauth/authorize-user`,
        request
      );
      return { success: true };
    } catch (err) {
      console.error(err);
      return { success: false };
    }
  },

  preSignUpAsync: async (request: UserPreSignUpRequest) =>
    new UserPreSignUpResponse(
      await PublicLiveApi.postAsync('auth/pre-sign-up', request)
    ),

  signUpAsync: async (request: UserSignUpRequest) =>
    new UserSignUpResponse(
      await PublicLiveApi.postAsync<UserSignUpResponse>('auth/sign-up', request)
    ),

  signInAsync: async (request: UserSignInRequest) => {
    const response = await PublicLiveApi.postAsync<UserSignInResponse>(
      'auth/sign-in',
      request
    );

    if (response.success && response.data) {
      SiteCache.getState().setUserId(response.data.userId);
      FrontSessionStore.getState().setSessionV2Id(
        response.data.userSessionV2Id
      );
    }
    return new UserSignInResponse(response);
  },

  signInVerifyAsync: async (request: UserSignInCodeRequest) => {
    const response = await PublicLiveApi.postAsync<UserSignInResponse>(
      'auth/sign-in-verify',
      request
    );

    if (response.success && response.data) {
      SiteCache.getState().setUserId(response.data.userId);
      FrontSessionStore.getState().setSessionV2Id(
        response.data.userSessionV2Id
      );
    }
    return new UserSignInResponse(response);
  },

  signInConfirmEmailAsync: (request: UserSignInConfirmEmailRequest) =>
    PublicLiveApi.postAsync('auth/sign-in-confirm-email', request),

  signOutAsync: async (siteId: Guid) => {
    const anomUser = new SiteUser(
      await PublicLiveApi.postAsync('auth/sign-out', {
        siteId
      })
    );
    SiteCache.getState().setUserId(new Guid(anomUser.userId));
    FrontSessionStore.getState().setSessionV2Id(anomUser.userSessionV2Id);
    return new SiteUser(anomUser);
  },

  forgotPasswordAsync: async (request: UserForgotPasswordRequest) =>
    new OperationResult(
      await PublicLiveApi.postAsync('auth/forgot-password', request)
    ),

  resetPasswordAsync: async (request: UserResetPasswordRequest) => {
    const user = new SiteUser(
      await PublicLiveApi.postAsync('auth/reset-password', request)
    );
    SiteCache.getState().setUserId(user.userId);
    FrontSessionStore.getState().setSessionV2Id(user.userSessionV2Id);
    return user;
  },

  verifyResetPasswordCodeAsync: async (code: string, siteId: Guid) =>
    new OperationResult(
      await PublicLiveApi.postAsync('auth/reset-password/verify', {
        code,
        siteId: siteId.toString()
      })
    ),

  thuziSignInAsync: async (request: ThuziSignInRequest) =>
    new ThuziSignInResponse(
      await PublicLiveApi.postAsync('auth/thuzi/signin', request)
    )
};
