import { FieldValidator } from './Validator';

export interface ValidationCharacterOptions {
  variant: 'min' | 'max';
  value: number;
  message?: string;
}

export const charactersValidator = ({
  variant,
  value: optionsValue,
  message
}: ValidationCharacterOptions): FieldValidator => {
  return (value: string) => {
    if (!value) return null;

    if (variant === 'min') {
      if (value.toString().length < optionsValue) {
        return message || `Must be more than ${optionsValue} characters`;
      }
    } else {
      if (value.toString().length > optionsValue) {
        return message || `Must be no more than ${optionsValue} characters`;
      }
    }

    return null;
  };
};
