import { Button, ButtonProps } from '@komo-tech/ui/Button';
import { UiSize } from '@komo-tech/ui/theme/types';
import { FC, ReactNode } from 'react';

import { Site } from '@/common/models/site/Site';

type StyledProps = ButtonProps['styledVariantProps'];

export interface AuthButtonProps
  extends StyledProps,
    Pick<
      ButtonProps,
      'onClick' | 'disabled' | 'busy' | 'size' | 'type' | 'w' | 'href'
    > {
  label?: ReactNode;
}

export const AuthButton: FC<AuthButtonProps> = ({
  bgColor,
  color,
  borderColor,
  label,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      variant="styled"
      styledVariantProps={{
        bgColor,
        color,
        borderColor
      }}
    >
      {label}
    </Button>
  );
};

interface AuthButtonGroupProps {
  buttons?: AuthButtonProps[];
  disabled?: boolean;
  size?: UiSize;
}

export const AuthButtonGroup: FC<AuthButtonGroupProps> = ({
  buttons = [],
  disabled: disabledProp,
  size: sizeProp
}) => {
  if (!buttons.length) {
    return null;
  }

  return (
    <>
      {buttons.map(({ disabled, size, ...rest }, i) => (
        <AuthButton
          key={i}
          size={size || sizeProp}
          {...rest}
          disabled={disabled || disabledProp}
        />
      ))}
    </>
  );
};

type Variant = 'primary' | 'secondary';

export const resolveAuthButtonProps = (
  site: Site,
  type: Variant,
  props?: Partial<AuthButtonProps>
) => {
  const button = (!props ? {} : { ...props }) as Partial<AuthButtonProps>;
  const {
    HeaderBackgroundColor,
    AuthButtonTextColor,
    AuthSubmitButtonColor,
    AuthOutlineButtonColor
  } = site.properties;
  switch (type) {
    case 'primary':
      button.bgColor = AuthSubmitButtonColor;
      button.color = AuthButtonTextColor;
      break;
    case 'secondary':
      button.bgColor = 'transparent';
      button.color = AuthOutlineButtonColor || HeaderBackgroundColor;
      button.borderColor = AuthOutlineButtonColor || HeaderBackgroundColor;
      break;
  }

  return button as AuthButtonProps;
};
