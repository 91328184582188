'use client';
import {
  DependencyList,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import isEqual from 'react-fast-compare';

// Source: https://github.com/kentcdodds/use-deep-compare-effect
// Alteration: swapped in our "react-fast-compare" dependency instead of the one they used

function checkDeps(deps: DependencyList) {
  if (!deps || !deps.length) {
    console.error(
      'useDeepCompareHooks should not be used with no dependencies. Use React.useMemo instead.'
    );
  }
  if (deps.every(isPrimitive)) {
    console.error(
      'useDeepCompareHooks should not be used with dependencies that are all primitive values. Use React.useMemo instead.'
    );
  }
}

function isPrimitive(val: unknown) {
  return val == null || /^[sbn]/.test(typeof val);
}

/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return useMemo(() => ref.current, [signalRef.current]);
}

export function useDeepCompareCallback<T extends (...args: any[]) => any>(
  callback: T,
  dependencies: DependencyList
): T {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  return useCallback(callback, useDeepCompareMemoize(dependencies));
}

type EffectCallback = Parameters<typeof useEffect>[0];

export function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
  skipDevCheck?: boolean
): void {
  if (!skipDevCheck && process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }

  return useEffect(callback, useDeepCompareMemoize(dependencies));
}

export function useDeepCompareLayoutEffect(
  callback: EffectCallback,
  dependencies: DependencyList
): void {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  return useLayoutEffect(callback, useDeepCompareMemoize(dependencies));
}

export function useDeepCompareEffectSkipInitial(
  callback: EffectCallback,
  dependencies: DependencyList
): void {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }

  const firstUpdate = useRef(true);

  return useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    callback();
  }, useDeepCompareMemoize(dependencies));
}

type MemoCallback<T = any> = () => T;
export function useDeepCompareMemo<T = any>(
  callback: MemoCallback<T>,
  dependencies: DependencyList,
  skipDevCheck?: boolean
): T {
  if (!skipDevCheck && process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  return useMemo(callback, useDeepCompareMemoize(dependencies));
}
