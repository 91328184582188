'use client';
import type { TextFactory, TextProps as MantineTextProps } from '@mantine/core';
import {
  polymorphicFactory as mantinePolymorphicFactory,
  Text as MantineText
} from '@mantine/core';

type TextReturnType = ReturnType<typeof mantinePolymorphicFactory<TextFactory>>;
export const Text = MantineText as TextReturnType;
export type TextProps = MantineTextProps;
