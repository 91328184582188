export const ValidationRegex = {
  //TODO IS THIS SMALL EMAIL REGEX BETTER THAN LONG BOI
  // Email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  jsonPath:
    /^([a-zA-Z_$][a-zA-Z\d_$]*(\.[a-zA-Z_$][a-zA-Z\d_$]*)*)?(\[\d+\])*$/,
  httpStatusCodes:
    /^[1-5][0-9][0-9](-[1-5][0-9][0-9])?(,[1-5][0-9][0-9](-[1-5][0-9][0-9])?)*$/
};

export const SpottifyRegex = {
  url: /^(https:\/\/open.spotify.com\/|spotify:)([a-zA-Z0-9]+)(.*)$/,
  embed: /https?:\/\/open\.spotify\.com\/(embed|embed-podcast)\//
};
