import { FieldValues } from 'react-hook-form';

export type FieldValidator<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown
> = (
  value: unknown,
  values: TFieldValues,
  validateData: TContext
) => string | null;

export class Validator<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown
> {
  private readonly _validators: FieldValidator<TFieldValues, TContext>[];

  constructor(validators?: FieldValidator<TFieldValues, TContext>[]) {
    this._validators = validators || [];
  }

  public validate(value: unknown, values: TFieldValues, context?: TContext) {
    if (!this._validators?.length || !values) return undefined;
    const errors: string[] = [];
    this._validators.forEach((validator) => {
      const error = validator(value, values, context);
      if (error) errors.push(error);
    });

    return !errors.length ? undefined : errors.join(', ');
  }
}
