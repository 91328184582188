import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

export class SiteUser {
  userId: Guid;
  isAnonymous: boolean;
  profileId?: Guid;
  attributes: Record<string, string>;
  hasPassedSiteEntryCondition?: boolean;
  hasContact: boolean;
  isContactVerified: boolean;
  hasVerifiedEmailV2: boolean;
  lastUpdated: Date;
  userSessionV2Id?: Guid;

  //Obsolete - remove next release
  unVerifiedEmails?: string[];

  constructor(props?: Partial<SiteUser>) {
    props = props || {};
    Object.assign(this, props);
    this.userId = Guid.valueOrEmpty(this.userId);
    this.profileId = Guid.valueOrUndefined(this.profileId);

    this.isAnonymous = asBoolean(this.isAnonymous);
    this.hasContact = asBoolean(this.hasContact);
    this.isContactVerified = asBoolean(this.isContactVerified);
    this.hasVerifiedEmailV2 = asBoolean(this.hasVerifiedEmailV2);

    this.lastUpdated = utcDateOrNow(this.lastUpdated);
    this.attributes = { ...this.attributes };
    this.userSessionV2Id = Guid.valueOrUndefined(this.userSessionV2Id);

    this.unVerifiedEmails = mapArray(this.unVerifiedEmails, (x) => x);
  }

  get hasEmail(): boolean {
    return !!this.email;
  }

  get email(): string {
    return (this.tryGetAttribute('email') || '').toLowerCase().trim();
  }

  get name(): string {
    return UserAttributesHelper.tryGetName(this.attributes);
  }

  get firstName(): string {
    return this.tryGetAttribute('first_name');
  }

  get lastName(): string {
    return this.tryGetAttribute('last_name');
  }

  get nameData() {
    let firstName = this.firstName;
    let lastName = this.lastName;
    const name = this.tryGetAttribute('name');

    //try revert to name attribute
    if ((!firstName || !lastName) && name) {
      const splitName = name.split(' ');
      //We got a match
      if (splitName.length === 2) {
        firstName = splitName[0];
        lastName = splitName[1];
      } else if (!firstName && !lastName) {
        firstName = name;
      }
    }

    return {
      firstName: firstName,
      lastName: lastName
    };
  }

  tryGetAttribute(attribute: string) {
    return tryGetAttribute(this.attributes, attribute);
  }

  getGreetingMessage(message?: string) {
    const codeFilter = new RegExp(/{{(.*?)}}/, 'g');
    if (message) {
      const codes = message.match(codeFilter);
      let greeting = message;
      if (!codes?.length) {
        return greeting;
      }
      codes.forEach((code) => {
        greeting = greeting.replace(
          code,
          this.tryGetAttribute(code.slice(2, -2))
        );
      });
      return greeting;
    }
    return 'Welcome back!';
  }

  tryGetInitials() {
    const initials: string[] = [];
    const { firstName, lastName } = this;
    if (firstName) initials.push(firstName.charAt(0).toUpperCase());
    if (lastName) initials.push(lastName.charAt(0).toUpperCase());
    return !initials.length ? undefined : initials.join('');
  }

  //Obsolete
  get verifiedEmail(): string {
    return this.isContactVerified &&
      this.unVerifiedEmails.every((e) => e != this.email)
      ? this.email
      : null;
  }

  //Obsolete
  get hasVerifiedEmail(): boolean {
    return !!this.verifiedEmail;
  }
}

const tryGetAttribute = (attributes: Record<string, string>, key: string) =>
  attributes[key] ?? '';

export const UserAttributesHelper = {
  tryGetName: (attributes: Record<string, string>) => {
    const name = attributes.name;
    if (name) {
      return name;
    }
    const firstName = attributes.first_name;
    const lastName = attributes.last_name;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  },
  tryGetEmail: (attributes: Record<string, string>) =>
    tryGetAttribute(attributes, 'email')
};
